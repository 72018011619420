import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {getCurrentDate} from './Functions.js';

class App extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      currentDate: '',
    }
  }

  componentDidMount(){
    setInterval(() => {
      var currentDate = getCurrentDate();
      this.setState({
        currentDate: currentDate
      })
    }, 1000)

  }

  render(){

    return (
      <div className="App">
        <header className="wallboard-header">
          <div className="datetime-today">{this.state.currentDate}</div>
          <img src='/logo-plus.jpg' className="logo" alt="logo" />
        </header>
  
        <div class="container choice-menu">
          <div class="row align-items-center">
            <div class="col-md-4 choice-block" onClick={() => window.location.href="/pcs"}><img src='/plus-logo-small.png' className="logo" alt="logo" /> <h3>PCS</h3></div>
            <div class="col-md-4 choice-block" onClick={() => window.location.href="/phd"}><img src='/plus-logo-small.png' className="logo" alt="logo" /> <h3>PHD</h3></div>
            <div class="col-md-4 choice-block" onClick={() => window.location.href="/ict"}><img src='/plus-logo-small.png' className="logo" alt="logo" /> <h3>ICT</h3></div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
