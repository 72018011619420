import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {getCurrentDate, getToken, dataICT, pauzeICT, checkRefreshToken} from '../Functions.js';

class WallboardICT extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      currentDate: '',
      dataICT: '',
      pauzeICT: ''
    }
  }

  componentDidMount(){
    setInterval(() => {
      var currentDate = getCurrentDate();
      checkRefreshToken();

      this.setState({
        currentDate: currentDate
      })
    }, 1000)

    if(!localStorage.getItem('token') || (localStorage.getItem('token') && localStorage.getItem('token') === '')){
      getToken();
    }

    setInterval(() => {
      var getDataICT = dataICT();
      var pauze = 0;
      var $this = this;
      pauzeICT(function(agentgroupid, data){
          if(agentgroupid && data){
            data.forEach(function(item){
                if(item.reason === 'Pauze' && item.currentState === "MakeBusy" && item.presentInAgentGroups.includes(agentgroupid)){
                    pauze += 1;
                }
            })
            $this.setState({
                pauzeICT: pauze
            })

          }
      });

      this.setState({
        dataICT: getDataICT
      })
    }, 5000)
  }

  render(){

    var ICTData = this.state.dataICT[0];
    var waitingQueueMax = '';

    if(ICTData){
      var totalagentsAvailable = ICTData.agentsUnavailable + ICTData.agentsAvailable;
      var getHours = ICTData.longestWaitingConversationDuration.split(":")[0];
      var getMinutes = ICTData.longestWaitingConversationDuration.split(":")[1];
      var getSeconds = ICTData.longestWaitingConversationDuration.split(":")[2];
      waitingQueueMax = getHours + ':' + getMinutes + ':' + getSeconds.toString()[0] + '' + getSeconds.toString()[1];
    }else{
      waitingQueueMax = "00:00:00";
    }

    if(ICTData){
        localStorage.setItem('waitingqueue', ICTData.waitingConversations);
        localStorage.setItem('waitingqueuetimemax', waitingQueueMax);
        localStorage.setItem('servicelevel', ICTData.serviceLevelPercentageToday);
        localStorage.setItem('answered', ICTData.answeredConversationsToday);
        localStorage.setItem('abandoned', ICTData.abandonedConversationsToday);
        localStorage.setItem('available', totalagentsAvailable);
        localStorage.setItem('idle', ICTData.agentsIdle);
        localStorage.setItem('pauze', this.state.pauzeICT ? this.state.pauzeICT : "0");
    }

    return (
      <div className="App">
        <header className="wallboard-header">
          <div className="datetime-today">{this.state.currentDate}</div>
          <img src='/logo-plus.jpg' className="logo" alt="logo" />
          <div className="department">ICT</div>
        </header>
  
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 wallboard-block"><span className="label">Wachtrij ICT</span><span className="content">{ICTData ? ICTData.waitingConversations : localStorage.getItem('waitingqueue')}</span></div>
            <div className="col-md-4 wallboard-block"><span className="label">Wachttijd Max</span><span className="content">{waitingQueueMax}</span></div>
            <div className="col-md-4 wallboard-block"><span className="label">Service Level</span><span className="content">{ICTData ? ICTData.serviceLevelPercentageToday : localStorage.getItem('servicelevel')}</span></div>
          </div>
          <div className="row">
            <div className="col-md-4 wallboard-block"><span className="label">Beantwoord</span><span className="content">{ICTData ? ICTData.answeredConversationsToday : localStorage.getItem('answered')}</span></div>
            <div className="col-md-4 wallboard-block"><span className="label">Verbroken</span><span className="content">{ICTData ? ICTData.abandonedConversationsToday : localStorage.getItem('abandoned')}</span></div>
            {/* <div className="col-md-3 wallboard-block"><span className="label">Voicemail</span><span className="content">0</span></div> */}
            <div className="col-md-4 wallboard-block"><span className="label">Pauze</span><span className="content">{this.state.pauzeICT ? this.state.pauzeICT : localStorage.getItem('pauze') ? localStorage.getItem('pauze') : 0}</span></div>
          </div>
          <div className="row">
            <div className="col-md-6 wallboard-block"><span className="label">Ingelogd</span><span className="content">{ICTData ? totalagentsAvailable : localStorage.getItem('available')}</span></div>
            <div className="col-md-6 wallboard-block"><span className="label">Beschikbaar</span><span className="content">{ICTData ? ICTData.agentsIdle : localStorage.getItem('idle')}</span></div>
            {/* <div className="col-md-4 wallboard-block"><span className="label">Opvolgen</span><span className="content">0</span></div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default WallboardICT;
