import Config from "./Config.json";

export const getCurrentDate = () => {
    const today = new Date();

    if((today.getMonth() + 1) < 10){
        var currentMonth = "0" + (today.getMonth() + 1)
    }else{
        currentMonth = (today.getMonth() + 1);
    }

    if(today.getDate() < 10){
        var currentDay = "0" + today.getDate();
    }else{
        currentDay = today.getDate();
    }

    if(today.getHours() < 10){
        var currentHour = "0" + today.getHours();
    }else{
        currentHour = today.getHours();
    }

    if(today.getMinutes() < 10){
        var currentMinutes = "0" + today.getMinutes();
    }else{
        currentMinutes = today.getMinutes();
    }

    if(today.getSeconds() < 10){
        var currentSeconds = "0" + today.getSeconds();
    }else{
        currentSeconds = today.getSeconds();
    }

    return currentDay + '-' + currentMonth + '-' + today.getFullYear() + ' ' + currentHour + ':' + currentMinutes + ':' + currentSeconds;
  };

export const datetimeDiff = (tokendate, tokenexpire) => {
    if(tokendate && tokenexpire){
        tokendate = new Date(tokendate);
        tokenexpire = new Date(tokenexpire);
        var diffMs = (tokenexpire - tokendate);
        var diffSecs = ((diffMs % 60000) / 1000).toFixed(0);
        var diffMins = Math.floor(diffMs / 60000);
        return diffMins + ":" + diffSecs;
    }
    
}

export const formatDate = (date, addHours) => {
    if(date){
        Date.prototype.addHours = function(h) {
            this.setTime(this.getTime() + (h*60*60*1000));
            return this;
        }
    
        if(addHours){
            date = new Date(date).addHours(1);
        }
    
        if((date.getMonth() + 1) < 10){
            var currentMonth = "0" + (date.getMonth() + 1)
        }else{
            currentMonth = (date.getMonth() + 1);
        }
    
        if(date.getDate() < 10){
            var currentDay = "0" + date.getDate();
        }else{
            currentDay = date.getDate();
        }
    
        if(date.getHours() < 10){
            var currentHour = "0" + date.getHours();
        }else{
            currentHour = date.getHours();
        }
    
        if(date.getMinutes() < 10){
            var currentMinutes = "0" + date.getMinutes();
        }else{
            currentMinutes = date.getMinutes();
        }
    
        if(date.getSeconds() < 10){
            var currentSeconds = "0" + date.getSeconds();
        }else{
            currentSeconds = date.getSeconds();
        }
    
        return date.getFullYear() + '-' + currentMonth + '-' + currentDay + ' ' + currentHour + ':' + currentMinutes + ':' + currentSeconds;
    }
    

}

export const getToken = () => {
    var details = Config.API_AUTH_CRED;
    
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch(Config.API_AUTH_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formBody
    })
    .then(response => response.json())
    .then(result => {
        var now = formatDate(new Date());
        localStorage.setItem('tokenDate', now);

        var expireDate = formatDate(new Date(), 1);
        localStorage.setItem('tokenExpire', expireDate);
        localStorage.setItem('token', result.access_token);
    })
};

export const checkRefreshToken = () => {
    if(localStorage.getItem('tokenDate') && localStorage.getItem('tokenExpire')){

        var currentTime = new Date().getTime();
        var expireTime = new Date(localStorage.getItem('tokenExpire')).getTime();

        if(currentTime > expireTime){
            localStorage.removeItem('token');
            window.location.reload();
        }
    }
}

export const dataPCS = () => {
    var PCSData = [];
    fetch(Config.API_URL + "/queues/" + Config.API_QUEUE.IDPCS + "/state?id=" + Config.API_QUEUE.IDPCS + "", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        PCSData.push(data);
    })
    return PCSData;
}

export const dataPHD = () => {
    var PHDData = [];
    fetch(Config.API_URL + "/queues/" + Config.API_QUEUE.IDPHD + "/state?id=" + Config.API_QUEUE.IDPHD + "", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        PHDData.push(data);
    })
    return PHDData;
}

export const dataICT = () => {
    var ICTData = [];
    fetch(Config.API_URL + "/queues/" + Config.API_QUEUE.IDICT + "/state?id=" + Config.API_QUEUE.IDICT + "", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        ICTData.push(data);
    })
    return ICTData;
}

export const pauzePCS = (callback) => {
    var pauze = 0;
    fetch(Config.API_URL + "/agents/groups/" + Config.API_AGENT_GROUPS.IDPCS + "/states?id=" + Config.API_AGENT_GROUPS.IDPCS + "", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        if(data && data._embedded){
            var items = data._embedded.items;
            var agentgroupid = Config.API_AGENT_GROUPS.IDICT;
            callback(agentgroupid, items);
        }
    })
    return pauze;
}

export const pauzePHD = (callback) => {
    var pauze = 0;
    fetch(Config.API_URL + "/agents/groups/" + Config.API_AGENT_GROUPS.IDPHD + "/states?id=" + Config.API_AGENT_GROUPS.IDPHD + "", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        if(data && data._embedded){
            var items = data._embedded.items
            callback(items);
        }
    })
    return pauze;
}

export const pauzeICT = (callback) => {
    var pauze = 0;
    fetch(Config.API_URL + "/agents/groups/" + Config.API_AGENT_GROUPS.IDICT + "/states?id=" + Config.API_AGENT_GROUPS.IDICT + "", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        if(data && data._embedded){
            var items = data._embedded.items
            var agentgroupid = Config.API_AGENT_GROUPS.IDICT;
            callback(agentgroupid, items);
        }
    })
    return pauze;
}

export const getVoicemail = () =>{
    var voicemail = [];
    fetch(Config.API_URL + "/queues/0111a4c4-cc5c-4620-9c28-e625b77bba35/state", {
        method: 'GET',
        headers: { 
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type" : "application/json", 
        }
    })
    .then(response => response.json())
    .then(data => {
        voicemail.push(data);
    })
    return voicemail;
}