import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {getCurrentDate, getToken, dataPCS, dataPHD, pauzePCS, getVoicemail, checkRefreshToken} from '../Functions.js';

class WallboardPCS extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      currentDate: '',
      dataPCS: '',
      pauzePCS: 0,
      dataPHD: '',
      voicemail: ''
    }
  }

  componentDidMount(){
    setInterval(() => {
      var currentDate = getCurrentDate();
      checkRefreshToken();

      this.setState({
        currentDate: currentDate
      })
    }, 1000)

    if(!localStorage.getItem('token') || (localStorage.getItem('token') && localStorage.getItem('token') === '')){
      getToken();
    }

    setInterval(() => {
      var getDataPCS = dataPCS();
      var getDataPHD = dataPHD();
      var voiceMail = getVoicemail();
      var pauze = 0;
      var $this = this;
      pauzePCS(function(agentgroupid, data){
          if(agentgroupid && data){
            data.forEach(function(item){
                if(item.reason === 'Pauze' && item.currentState === "MakeBusy" && item.presentInAgentGroups.includes(agentgroupid)){
                    pauze += 1;
                }
            })
            $this.setState({
                pauzePCS: pauze
            })

          }else{
            $this.setState({
              pauzePCS: 0
          })
          }
      });

      this.setState({
        dataPCS: getDataPCS,
        dataPHD: getDataPHD,
        voicemail: voiceMail
      })
    }, 5000)
    
  }

  render(){

    var PCSData = this.state.dataPCS[0];
    var PHDData = this.state.dataPHD[0];
    var VoiceMaildata = this.state.voicemail[0];

    var waitingQueueMax = '';

    if(VoiceMaildata){
      var getVoicemails = VoiceMaildata.waitingConversations;
    }

    if(PCSData){
      var totalagentsAvailable = PCSData.agentsUnavailable + PCSData.agentsAvailable;
      var getHours = PCSData.longestWaitingConversationDuration.split(":")[0];
      var getMinutes = PCSData.longestWaitingConversationDuration.split(":")[1];
      var getSeconds = PCSData.longestWaitingConversationDuration.split(":")[2];
      waitingQueueMax = getHours + ':' + getMinutes + ':' + getSeconds.toString()[0] + '' + getSeconds.toString()[1];
    }else{
      waitingQueueMax = "00:00:00";
    }

    if(PCSData && PHDData && VoiceMaildata){
        localStorage.setItem('waitingqueuephd', PHDData.waitingConversations);
        localStorage.setItem('waitingqueue', PCSData.waitingConversations);
        localStorage.setItem('waitingqueuetimemax', waitingQueueMax);
        localStorage.setItem('servicelevel', PCSData.serviceLevelPercentageToday);
        localStorage.setItem('answered', PCSData.answeredConversationsToday);
        localStorage.setItem('abandoned', PCSData.abandonedConversationsToday);
        localStorage.setItem('available', totalagentsAvailable);
        localStorage.setItem('idle', PCSData.agentsIdle);
        localStorage.setItem('pauze', this.state.pauzePCS ? this.state.pauzePCS : "0");
        localStorage.setItem('voicemail', getVoicemails);
    }

    return (
      <div className="App">
        <header className="wallboard-header">
          <div className="datetime-today">{this.state.currentDate}</div>
          <img src='/logo-plus.jpg' className="logo" alt="logo" />
          <div className="department">PCS</div>
        </header>
  
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 wallboard-block"><span className="label">Wachtrij PCS</span><span className="content">{PCSData ? PCSData.waitingConversations : localStorage.getItem('waitingqueue')}</span></div>
            <div className="col-md-3 wallboard-block"><span className="label">Wachttijd Max</span><span className="content">{waitingQueueMax}</span></div>
            <div className="col-md-3 wallboard-block"><span className="label">Service Level</span><span className="content">{PCSData ? PCSData.serviceLevelPercentageToday : localStorage.getItem('servicelevel')}</span></div>
            <div className="col-md-3 wallboard-block"><span className="label">Wachtrij PHD</span><span className="content">{PHDData ? PHDData.waitingConversations : localStorage.getItem('waitingqueuephd')}</span></div>
          </div>
          <div className="row">
            <div className="col-md-3 wallboard-block"><span className="label">Beantwoord</span><span className="content">{PCSData ? PCSData.answeredConversationsToday : localStorage.getItem('answered')}</span></div>
            <div className="col-md-3 wallboard-block"><span className="label">Verbroken</span><span className="content">{PCSData ? PCSData.abandonedConversationsToday : localStorage.getItem('abandoned')}</span></div>
            <div className="col-md-3 wallboard-block"><span className="label">Voicemail</span><span className="content">{VoiceMaildata ? getVoicemails : localStorage.getItem('voicemail')}</span></div>
            <div className="col-md-3 wallboard-block"><span className="label">Pauze</span><span className="content">{this.state.pauzePCS ? this.state.pauzePCS : localStorage.getItem('pauze')}</span></div>
          </div>
          <div className="row">
            <div className="col-md-6 wallboard-block"><span className="label">Ingelogd</span><span className="content">{PCSData ? totalagentsAvailable : localStorage.getItem('available')}</span></div>
            <div className="col-md-6 wallboard-block"><span className="label">Beschikbaar</span><span className="content">{PCSData ? PCSData.agentsIdle : localStorage.getItem('idle')}</span></div>
            {/* <div className="col-md-4 wallboard-block"><span className="label">Opvolgen</span><span className="content">0</span></div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default WallboardPCS;
